import React from 'react';
import scripts from '../../assets/data/scripts';
import './GridOutput.css';

class GridOutput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'scripts': scripts
    };
  }

  render() {
    const char = this.props.chars[this.props.charKey]['DEF'];
    const scripts = this.state.scripts;

    const items = Object.keys(scripts).map(function(key) {
      const className = `GridOutput-item GridOutput-item-${key}`;

      return (
        <div
          className={className}
          key={key}
          >
          <div
            className="GridOutput-item-script"
            >
              {scripts[key]['unicode']}
          </div>
          <div
            className="GridOutput-item-char"
            >
              {char[scripts[key]['index']]}
          </div>
        </div>
      );
    });

    return (
      <div className="GridOutput">
        {items}
      </div>
    );
  }
}

export default GridOutput;
