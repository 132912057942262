import AppHeader from './AppHeader';
import GridInput from '../Grid/GridInput';
import GridOutput from '../Grid/GridOutput';
import React from 'react';
import chars from '../../assets/data/chars';
import diacritics from '../../assets/data/diacritics'
import scripts from '../../assets/data/scripts';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'charKey': 'A',
      'chars': chars,
      'diacritics': diacritics,
      'scriptKey': "HIR",
      'scripts': scripts
    };

    this.selectChar = this.selectChar.bind(this);
    this.setScriptKey = this.setScriptKey.bind(this);
  }

  selectChar(charKey) {
    this.setState({
      'charKey': charKey
    });
  }

  setScriptKey(scriptKey) {
    this.setState({
      'scriptKey': scriptKey
    });
  }

  render() {
    return (
      <div className="App">
        <AppHeader
          items={this.state.scripts}
          scriptKey={this.state.scriptKey}
          callback={this.setScriptKey}
          />
        <GridInput
          charKey={this.state.charKey}
          chars={this.state.chars}
          diacritics={this.state.diacritics}
          scriptIndex={this.state.scripts[this.state.scriptKey]['index']}
          scriptKey={this.state.scriptKey}
          callback={this.selectChar}
          />
        <GridOutput
          chars={this.state.chars}
          charKey={this.state.charKey}
          />
      </div>
    );
  }
}

export default App;
