import React from 'react';
import './AppHeader.css';

class AppHeader extends React.Component {
  render() {
    const items = this.props.items;
    const callback = this.props.callback;
    const scriptKey = this.props.scriptKey;

    const navItems = Object.keys(items).map(function(key) {
      const activeClassName = scriptKey === key ? 'AppHeader-item-active' : '';
      const className = `AppHeader-item AppHeader-item-${key} ${activeClassName}`;

      return (
        <button
          className={className}
          key={key}
          onClick={() => callback(key)}
          title={items[key].name}
          >
          {items[key].unicode}
        </button>
      );
    });

    return (
      <div className="AppHeader">
        <div className="AppHeader-item AppHeader-item-logo">KG</div>
        {navItems}
      </div>
    );
  }
}

export default AppHeader;
