import React from 'react';
import charsSimilar from '../../assets/data/charsSimilar';
import './GridInput.css';

class GridInput extends React.Component {
  constructor(props) {
    super(props);

    this.getSimilarCharKeys = this.getSimilarCharKeys.bind(this);
  }

  getSimilarCharKeys(charKey) {
    const scriptChars = charsSimilar[this.props.scriptKey];

    for (var i = 0; i < scriptChars.length; i++) {
      const isInArray = scriptChars[i].includes(charKey);

      if (true === isInArray) {
        return scriptChars[i];
      }
    }

    return [];
  }

  render() {
    const callback = this.props.callback;
    const chars = this.props.chars;
    const diacritics = this.props.diacritics;
    const scriptIndex = this.props.scriptIndex;
    const charKey = this.props.charKey;
    const similarChars = this.getSimilarCharKeys(charKey);

    const charItems = Object.keys(chars).map(function(key) {
      const charUnicode = chars[key]['DEF'][scriptIndex];
      const pos = chars[key]['pos'];

      const posClassName = `GridInput-item-col-${pos[0]} GridInput-item-row-${pos[1]}`;
      const activeClassName = charKey === key ? 'GridInput-item-active' : '';
      const similarClassName = (charKey !== key && similarChars.includes(key)) ? 'GridInput-item-similar' : '';
      const className = `GridInput-item ${posClassName} ${activeClassName} ${similarClassName}`;

      return (
        <button
          className={className}
          key={key}
          onClick={() => callback(key)}
          >
          {charUnicode}
        </button>
      );
    });

    const diacriticItems = Object.keys(diacritics).map(function(key) {
      const className = `GridInput-item`;

      return (
        <button
          className={className}
          key={key}
          onClick={() => callback(key)}
          >
          {diacritics[key]['unicode']}
        </button>
      );
    });

    return (
      <div className="GridInput">
        {charItems}
        {/* {diacriticItems} */}
      </div>
    );
  }
}

export default GridInput;
